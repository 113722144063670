import { useState } from 'react';
import {
  Box,
  CardContent,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Menu,
  useTheme,
} from '@mui/material';
import {
  ArrowRight,
  Concrete,
  Edit,
  Location,
  MenuIcon,
  Sensor,
} from '../icons';
import {
  StyledCardHeader,
  StyledListItem,
  StyledProjectCard,
} from '../project-card/project-card.styled';
import { t } from 'i18next';
import { ISensorItem } from '@/models';
import { routesDetails } from '@/constants';
import { useNavigate } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import { StyledMenuItem } from '../pour-card';
import { useUnitType,useCubeOrCylinder } from '@/hooks';
import { mpaToPsi,cubeToCelinder } from '@/utils';

interface IProjectCardProps {
  isDisabled: boolean;
  sensorData: ISensorItem;
  pourId: string;
  pourName: string;
  handleDeleteSensorClick: (sensorId: string) => void;
  projectId: string;
}

const SensorCard = ({
  sensorData = {} as ISensorItem,
  pourId,
  isDisabled,
  projectId,
  pourName,
  handleDeleteSensorClick,
}: IProjectCardProps) => {
  const theme = useTheme();
  const { isMetric } = useUnitType();
  const { isCylinder } = useCubeOrCylinder();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { name, display_name, location, sensor_id, last_strength } = sensorData;
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: any) => {
    e?.stopPropagation?.();
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ overflow: 'auto', flex: 1 }}>
        <StyledProjectCard
          onClick={() =>
            navigate(
              routesDetails.Sensor(
                projectId,
                pourId,
                pourName,
                sensor_id,
                name ?? display_name,
                isDisabled
              )
            )
          }
        >
          <StyledCardHeader
            action={
              <IconButton aria-label='view Project'>
                <ArrowRight />
              </IconButton>
            }
            title={name ?? display_name}
          />

          <CardContent sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                top: '0',
                right: '0',
                zIndex: '99',
              }}
            >
              <IconButton
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                aria-label='options'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <StyledMenuItem
                  disabled={isDisabled}
                  onClick={e => {
                    e.stopPropagation();
                    handleDeleteSensorClick(sensor_id);
                    handleClose(e);
                  }}
                >
                  <Box
                    component='span'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      '& svg': {
                        width: '15px',
                        height: '15px',
                        fill: 'red',
                      },
                    }}
                  >
                    <CloseIcon />
                  </Box>
                  {t('delete')}
                </StyledMenuItem>
                <StyledMenuItem
                  disabled={isDisabled}
                  onClick={e => {
                    e.stopPropagation();
                    navigate(routesDetails.editSensor(sensor_id,projectId, pourId));
                    handleClose(e);
                  }}
                >
                  <span>
                    <Edit />
                  </span>
                  {t('editSensor')}
                </StyledMenuItem>
              </Menu>
            </Box>
            <List dense disablePadding>
              <StyledListItem>
                <ListItemIcon>
                  <Location />
                </ListItemIcon>
                <ListItemText>
                  <span>{`${t('location')} :`}</span>
                  {location}
                </ListItemText>
              </StyledListItem>
              <StyledListItem>
                <ListItemIcon
                  sx={{
                    '& path': {
                      fill: theme.palette.primary.main,
                      fillOpacity: 1,
                    },
                  }}
                >
                  <Sensor />
                </ListItemIcon>
                <ListItemText>
                  <span>{`${t('sensorId')} :`}</span>
                  {sensor_id}
                </ListItemText>
              </StyledListItem>
              <StyledListItem>
                <ListItemIcon
                  sx={{
                    '& path': {
                      fill: theme.palette.primary.main,
                      fillOpacity: 1,
                    },
                  }}
                >
                  <Concrete />
                </ListItemIcon>
                <ListItemText>
                  <span>{`${t('lastStrength')} :`}</span>
                  {last_strength?
                  isMetric?
                       isCylinder?
                        `${cubeToCelinder(last_strength)?.toFixed(2)} ${t('mpa')}` 
                        : `${last_strength?.toFixed(2)} ${t('mpa')}` 
                  :isCylinder?
                       `${mpaToPsi(cubeToCelinder(last_strength))?.toFixed(2)} ${t('psi')}` 
                  :`${mpaToPsi(last_strength)?.toFixed(2)} ${t('psi')}`
                  : `${t('NA')}`}
                </ListItemText>
              </StyledListItem>
            </List>
          </CardContent>
        </StyledProjectCard>
      </Box>
    </>
  );
};

export default SensorCard;
